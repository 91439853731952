





















































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
} from "@auditcloud/shared/lib/utils/type-guards";

@Component({})
export default class ATagInput extends Vue {
  activator: any = null;
  attach: any = null;
  colors: any = ["green", "purple", "indigo", "cyan", "teal", "orange"];
  editing: any = null;
  index: number = -1;
  items: any = [
    { header: "Select an option or create one" },
    {
      text: "Foo",
      color: "blue",
    },
    {
      text: "Bar",
      color: "red",
    },
  ];
  nonce: number = 1;
  menu: boolean = false;
  model: any = [
    {
      text: "Foo",
      color: "blue",
    },
  ];
  x: number = 0;
  search: any = null;
  y: number = 0;

  @Watch("model")
  onWatchModel(val, prev) {
    if (val.length === prev.length) return;

    this.model = val.map(v => {
      if (typeof v === "string") {
        v = {
          text: v,
          color: this.colors[this.nonce - 1],
        };

        this.items.push(v);

        this.nonce++;
      }

      return v;
    });
  }

  edit(index, item) {
    if (!this.editing) {
      this.editing = item;
      this.index = index;
    } else {
      this.editing = null;
      this.index = -1;
    }
  }
  filter(item, queryText, itemText) {
    if (item.header) return false;

    const hasValue = val => (val != null ? val : "");

    const text = hasValue(itemText);
    const query = hasValue(queryText);

    return (
      text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    );
  }
}
