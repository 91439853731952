






































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import firebase from "firebase/compat/app";

import { api } from "@/store/modules/audit";
import { AuditItem } from "@auditcloud/shared/lib/schemas";
import ATagInput from "@/components/controls/ATagInput.vue";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { SortSetting } from "@auditcloud/shared/lib/types/Audit/types";
import { SortManipulator } from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";

// import { VIcon, VFooter } from "vuetify/lib";

@Component({ components: { ATagInput } })
export default class AAuditItemSortAction extends Vue {
  @Getter(api.getters.getAuditItemListSortManipulators, {
    namespace: api.namespace,
  })
  sort_by_manipulator!: SortManipulator[];

  @Getter(api.getters.getAuditItemListSortSettings, {
    namespace: api.namespace,
  })
  sort_by!: SortSetting[];

  get reversedSortBy(): SortSetting[] {
    return [...this.sort_by].reverse();
  }

  @Mutation(api.mutations.PUSH_SORT_SETTING, { namespace: api.namespace })
  pushSortSetting!: (payload: SortSetting) => void;

  @Mutation(api.mutations.REMOVE_SORT_SETTING, { namespace: api.namespace })
  removeSortSetting!: (id: string) => void;

  pushSortBy(id: string, desc: boolean) {
    this.pushSortSetting({ id, desc });
  }
  removeSortBy(id: string) {
    this.removeSortSetting(id);
  }

  sorterName(id: string): string {
    const sorter = this.sort_by_manipulator.find(v => v.id === id);
    if (sorter) {
      return this.$t(sorter.name).toString();
    } else {
      return id;
    }
  }
}
